"use client";

import Image from "next/image";
import ReactMarkdown from "react-markdown";
import { HomePage } from "../../interfaces/home-model";
import styles from "./AboutBlock.module.scss";

interface PageProps {
  data: HomePage;
  hidden: boolean;
}

const AboutBlock = ({ data, hidden }: PageProps) => {
  return (
    <div className={styles.about_block}>
      <div className={`container ${styles.about_block__container}`}>
        <article className={styles.content}>
          <h2 className={styles.content__title} hidden={hidden}>
            {/* {t("title")} */}
            {/* {?.about_title} */}
            {data?.about_title}
          </h2>
          <div className={styles.content__desc}>
            {/* <p>{t("descPara1")}</p> */}
            {/* <p>{t("descPara2")}</p> */}
            <ReactMarkdown>{data?.about_description}</ReactMarkdown>
          </div>
        </article>
        <div className={styles.image_wrapper}>
          <div className={styles.image_wrapper__container}>
            <Image
              src={data?.about_image?.data?.attributes?.url}
              alt={
                data?.about_image?.data?.attributes?.alternativeText ||
                "About Image"
              }
              fill
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBlock;
